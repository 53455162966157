<template>
    <div>
        <message messageKey="messaggio-show" />
        <error-banner v-if="errors" :close="true" />
        <div v-if="manage && manage.stato != 'CONFERMATA'">
            <div
                class="alert alert-warning mt-2 position-relative"
                ref="messages"
                tabindex="0"
            >
                <ul class="list-unstyled">
                    {{
                        $t("manage.not_confirmed")
                    }}
                </ul>
            </div>
        </div>
        <div class="container mt-5" v-if="manage">
            <modal v-if="showModal" @close="showModal = false">
                <h3 slot="header">{{ $t("manage.pop-up.title") }}</h3>
                <p slot="body">
                    <span v-if="manage.stato != 'CONFERMATA'">
                        {{ $t("manage.pop-up.description-richiesta") }}
                    </span>
                    <span v-else>
                        {{ $t("manage.pop-up.description") }}
                    </span>
                </p>
                <div slot="footer">
                    <div>
                        <e-button
                            class="btn btn-lg btn-outline-danger"
                            :danger="true"
                            @click="elimina"
                        >
                            {{ $t("buttons.elimina") }}
                        </e-button>
                        <e-button
                            class="btn btn-lg btn-outline-secondary"
                            @click="showModal = false"
                        >
                            {{ $t("buttons.annulla") }}
                        </e-button>
                    </div>
                </div>
            </modal>
            <modal
                v-if="modaleConfermaPresenza"
                @close="modaleConfermaPresenza = false"
            >
                <h4 slot="header" class="text-wrap">
                    {{ $t("manage.show.conferma_presenza.modale.title") }}
                </h4>
                <div slot="body">
                    <lable for="postazione">
                        {{
                            $t(
                                "manage.show.conferma_presenza.modale.description"
                            )
                        }}
                    </lable>
                    <input
                        type="text"
                        id="postazione"
                        name="postazione"
                        class="form-control"
                        v-model="postazione"
                        :placeholder="
                            $t(
                                'manage.show.conferma_presenza.modale.placeholder'
                            )
                        "
                    />
                    <span v-if="postazioneIsEmpty" class="text-danger"
                        >il campo deve essere valorizzato</span
                    >
                </div>
                <div slot="footer">
                    <div>
                        <e-button
                            outlined
                            @click="confermaPresenza"
                            class="btn-lg mx-auto"
                        >
                            {{ $t("buttons.confirm") }}
                        </e-button>
                    </div>
                </div>
            </modal>
            <div
                class="form-header--riepilogo-riepilogo lg"
                v-if="manage && form"
            >
                <div class="form-header-riepilogo-riepilogo--box">
                    <ul class="form-header--riepilogo-riepilogo--luogo lg">
                        <li class="mr-2 servizio">
                            <i class="fas fa-concierge-bell mt-1"></i>
                            <span v-html="manage.servizio.type"> </span>
                        </li>

                        <li class="mr-2 area" v-if="manage.area.name">
                            <i class="far fa-building mt-1"></i>
                            <span v-html="manage.area.name"></span>
                        </li>

                        <li class="indirizzo" v-if="manage.area.address">
                            <i class="fas fa-map-marker-alt mt-1"></i>
                            <span v-html="manage.area.address"></span>
                        </li>
                        <li
                            class="indirizzo"
                            v-if="
                                manage.entry &&
                                    !publicPosition &&
                                    !manage.servizio.servizio_breve
                            "
                        >
                            <i class="fas fa-laptop-house mt-1"></i>
                            <span v-html="manage.risorsa"></span>
                        </li>
                    </ul>
                    <ul class="form-header--riepilogo-riepilogo--dati lg">
                        <li v-if="!hideEndTimeFromSofware">
                            <i class="fas fa-hourglass-half"></i>
                            {{
                                secondsToString(
                                    manage.entry.end_time -
                                        manage.entry.start_time,
                                    this
                                )
                            }}
                        </li>
                        <li>
                            <i class="far fa-calendar-alt"></i>
                            {{
                                new Date(
                                    manage.entry.day_time
                                ).toLocaleDateString(lang, {
                                    weekday: "short",
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric"
                                })
                            }}
                        </li>
                        <li>
                            <i class="far fa-clock"></i>
                            {{
                                new Date(
                                    new Date(
                                        manage.entry.start_time * 1000
                                    ).toLocaleString("en-US", {
                                        timeZone: timezone
                                    })
                                ).toLocaleTimeString(lang, {
                                    hour: "2-digit",
                                    minute: "2-digit"
                                })
                            }}
                            <template v-if="!hideEndTimeFromSoftware">
                            -
                            {{
                                new Date(
                                    new Date(
                                        manage.entry.end_time * 1000
                                    ).toLocaleString("en-US", {
                                        timeZone: timezone
                                    })
                                ).toLocaleTimeString(lang, {
                                    hour: "2-digit",
                                    minute: "2-digit"
                                })
                            }}
                            </template>
                        </li>
                    </ul>
                </div>

                <ul class="form-header--riepilogo-riepilogo--datiutente lg">
                    <li
                        v-for="(dati, i) in datiUtente"
                        :key="i"
                        :class="{
                            'd-none':
                                form.filter(e => e.codice == i)[0].tipo ==
                                    'checkbox' ||
                                dati == '' ||
                                dati == null
                        }"
                    >
                        <template
                            v-if="
                                form.filter(e => e.codice == i)[0].tipo ==
                                    'select'
                            "
                        >
                            <span v-tooltip="formLabel(i)">
                                {{ formLabel(i) }}:
                            </span>
                            <span class="dato">{{
                                form.filter(e => e.codice == i)[0].valori[dati]
                            }}</span>
                        </template>
                        <template v-else>
                            <span v-tooltip="formLabel(i)">
                                {{ formLabel(i) }}:
                            </span>
                            <span class="dato"> {{ dati }}</span>
                        </template>
                    </li>
                </ul>

                <div
                    class="row my-3 position-sticky bottom-0 p-2 bg-white"
                    v-if="new Date(manage.entry.end_time * 1000) > new Date()"
                >
                    <div
                        class="col-12 my-2"
                        v-if="publicPosition && prenotazioneInCorso"
                    >
                        <!-- v-if="" -->
                        <e-button
                            :disabled="manage.confermata"
                            outlined
                            class="btn btn-lg w-100"
                            @click="showModaleConfermaPresenza"
                        >
                            {{
                                manage.confermata
                                    ? $t(
                                          "manage.show.conferma_presenza.button_confirmed"
                                      )
                                    : $t("manage.show.conferma_presenza.button")
                            }}
                        </e-button>
                    </div>
                    <div
                        class="col-12 my-2"
                        v-if="
                            manage.libera_posto &&
                                prenotazioneInCorso &&
                                showLiberaPosto &&
                                !manage.servizio.servizio_breve
                        "
                    >
                        <e-button @click="liberaPosto" class="btn btn-lg w-100">
                            {{ $t("buttons.libera_posto") }}
                        </e-button>
                    </div>
                    <div
                        class="col-6"
                        v-if="
                            !prenotazionePassata &&
                                campiModificabili &&
                                !nascondiModifica &&
                                !prenotazioneInCorso
                        "
                    >
                        <e-button @click="edit" class="btn btn-lg w-100">
                            {{ $t("buttons.modifica") }}
                        </e-button>
                    </div>
                    <div
                        :class="
                            campiModificabili && !nascondiModifica
                                ? 'col-6'
                                : 'col-12'
                        "
                        v-if="!prenotazionePassata && !prenotazioneInCorso"
                    >
                        <e-button
                            @click="showModal = true"
                            outlined
                            class="btn btn-lg w-100"
                            :submit="false"
                        >
                            {{ $t("buttons.elimina") }}
                        </e-button>
                    </div>
                </div>
            </div>
            <e-link
                class="btn btn-lg btn-block text-white"
                :fileName="'QR_CODE_PRENOTAZIONE.png'"
                v-if="manage.qr_code"
                :href="manage.qr_code"
                >{{ $t("buttons.download_qr_code") }}
            </e-link>
            <e-button
                class="btn-lg mx-auto my-3 w-100"
                :submit="false"
                @click="downloadPdf"
                v-if="!nascondiPdfPassato"
                >{{ $t("download_pdf") }}</e-button
            >
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Modal from "../../components/Modal";
import ErrorBanner from "../../components/ErrorBanner";
import Message from "../../components/layout/Message";
import { publicPosition } from "../../mixins/publicPosition";
import { showsEndTime } from "../../mixins/showsEndTime.js";
/**
 * @vuese
 * @group Gestisci
 * Mostra i dati della prenotazione e permette di gestirla/modificarla
 */
export default {
    mixins: [publicPosition, showsEndTime],
    components: {
        Modal,
        ErrorBanner,
        Message
    },
    computed: {
        ...mapState({
            manage: state => state.Manage.manage,
            form: state => state.Utils.form,
            datiUtente: state => state.Manage.manage.public_fields,
            errors: state => state.Utils.errors,
            cliente: state => state.Ente.cliente,
            nascondiModifica: state =>
                state.Ente.configs.portale_pubblico &&
                state.Ente.configs.portale_pubblico.nascondi_tasto_modifica &&
                state.Ente.configs.portale_pubblico.nascondi_tasto_modifica ==
                    "1",
            configNascondiPdf: state =>
                state.Ente.configs.portale_pubblico &&
                state.Ente.configs.portale_pubblico.nascondi_pdf_passato == "1"
        }),
        timezone() {
            return process.env.VUE_APP_TIMEZONE;
        },
        nascondiPdfPassato() {
            return this.prenotazionePassata && this.configNascondiPdf;
        },
        prenotazioneInCorso() {
            return (
                new Date(this.manage.entry.start_time * 1000) < new Date() &&
                new Date(this.manage.entry.end_time * 1000) > new Date()
            );
        },
        prenotazionePassata() {
            return new Date(this.manage.entry.end_time * 1000) < new Date();
        },
        campiModificabili() {
            return (
                this.form.filter(e => e.primaria != 1 && e.identificativo != 1)
                    .length > 0
            );
        }
    },
    data() {
        return {
            showModal: false,
            showLiberaPosto: true,
            modaleConfermaPresenza: false,
            postazione: "",
            postazioneIsEmpty: false
        };
    },
    beforeRouteLeave(_, __, next) {
        this.clearErrors();
        next();
    },
    methods: {
        /**
         * @vuese
         * MapMutations
         * Ritorna mutations dallos state
         * @mutations setMessage [imposta messaggi di successo]
         * @mutations setErrors  [imposta messaggi di errore]
         */
        ...mapMutations({
            setMessage: "Utils/SET_MESSAGES",
            setErrors: "Utils/SET_ERRORS",
            clearErrors: "Utils/CLEAR_ERRORS"
        }),
        /**
         * @vuese
         * Chiama api per effettuare il download del pdf e scaricarlo
         */
        downloadPdf() {
            window
                .open(
                    `${process.env.VUE_APP_SERVER_URL}/api/entry/pdf/${this.manage.entry.id}`,
                    "_blank"
                )
                .focus();
        },
        /**
         * @vuese
         * @arg indice :number
         * cerca label del campo ritrona la traduzione se disponibile o l'originale se non disponibile
         */
        formLabel(i) {
            const campo = this.form.filter(e => e.codice == i)[0].label;
            return this.$t(`form.campi.${campo}`) == `form.campi.${campo}`
                ? campo
                : this.$t(`form.campi.${campo}`);
        },
        /**
         * @vuese
         * elimina la prenotazione
         * in caso di errori utilizza setErrors per mostrarli in ErrorBanner
         */
        elimina() {
            this.$http
                .post(
                    `api/entry/delete/${this.manage.codice}?chiave=${this.manage.primaria}`
                )
                .then(() => (this.showModal = false))
                .then(() => {
                    this.setMessage({
                        name: "DELETED",
                        message: this.$t("manage.deleted." + this.manage.stato)
                    });
                    this.$router.push({ name: "HomeCliente" });
                })
                .catch(e => {
                    this.showModal = false;
                    this.setErrors(e);
                });
        },
        /**
         * @vuese
         * Chiama api per la funzione libera posto
         */
        liberaPosto() {
            this.$http
                .post(
                    `/api/entry/update/${this.manage.codice}?chiave=${this.manage.primaria}`,
                    { type: "libera_posto" }
                )
                .then(() => {
                    this.setMessage({
                        name: "LIBERA_POSTO",
                        message: "Posto liberato correttamente"
                    });
                    this.showLiberaPosto = false;
                })
                .catch(e => this.setErrors(e));
        },
        showModaleConfermaPresenza() {
            this.modaleConfermaPresenza = true;
        },
        confermaPresenza() {
            if (this.postazione.trim() == "") {
                this.postazioneIsEmpty = true;
                return;
            }
            const url = `/api/entry/update/${this.manage.codice}?chiave=${this.manage.primaria}`;
            this.$http
                .post(url, {
                    postazione: this.postazione,
                    type: "conferma_presenza"
                })
                .then(() => {
                    this.setMessage({
                        name: "CONFERMA_PRESENZA",
                        message: "Presenza confermata correttamente"
                    });
                    this.modaleConfermaPresenza = false;
                    this.manage.confermata = true;
                })
                .catch(e => this.setErrors(e));
        },
        edit() {
            this.$router.push({
                name: "Edit",
                query: {
                    chiave: this.manage.primaria
                }
            });
        }
    }
};
</script>

<style scoped>
.form-header-riepilogo-riepilogo--box {
    border: 1px solid rgb(211, 211, 211);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 20%;
}

ul {
    padding: 0.3rem;
}

.form-header--riepilogo-riepilogo {
    background-color: white;
    display: flex;
    background-color: white;
    /* border: 1px solid rgb(211, 211, 211); */
    padding: 0.5rem 1rem;
    border-radius: 10px;
    justify-content: center;
    flex-direction: column;
    color: #666;
}

.form-header--riepilogo-riepilogo.lg {
    background-color: white;
    padding: 0.9rem 1.5rem;
    display: flex;
    flex-direction: column;
}

.form-header--riepilogo-riepilogo--luogo {
    margin-bottom: 0.3rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.form-header--riepilogo-riepilogo--luogo.lg,
.form-header--riepilogo-riepilogo--datiutente {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
}

.form-header--riepilogo-riepilogo--datiutente span:not(.dato) {
    font-size: 0.9rem;
    font-weight: bold;
    word-break: break-all;
}

.dato {
    width: 50%;
    word-break: break-all;
    display: inline-block;
}

.form-header--riepilogo-riepilogo--datiutente li {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0.3rem 0;
    padding: 0.4rem 0;
    border-bottom: 1px solid rgb(211, 211, 211);
    flex-wrap: wrap;
}

.form-header--riepilogo-riepilogo--luogo.lg li {
    margin-bottom: 10px;
    display: flex;
}

.form-header--riepilogo-riepilogo--luogo.lg li i {
    margin-right: 20px;
    font-size: 1.2rem;
}

.form-header--riepilogo-riepilogo--dati.lg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    font-size: 1rem;
    margin-top: 1rem auto;
    flex-wrap: wrap;
}

.form-header--riepilogo-riepilogo--dati.lg li {
    margin-right: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-header--riepilogo-riepilogo--dati.lg li i {
    margin-right: 10px;
}

.form-header--riepilogo-riepilogo--dati {
    display: flex;
    justify-content: space-around;
    font-size: 0.8rem;
}

.form-header--riepilogo {
    background: #eee;
    width: 100%;

    padding: 1rem 8rem;

    overflow: hidden;
    border-radius: 10px;
}

@media screen and (max-width: 600px) {
    .form-header--riepilogo-riepilogo.lg {
        width: 100%;
        padding: 0;
    }

    .form-header--riepilogo {
        padding: 0.8rem 0.3rem;
    }

    .form-header-riepilogo-riepilogo--box {
        padding-left: 1rem;
    }
}
</style>
